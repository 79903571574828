import htmlPackage from 'components/objects/htmlBlock/package';
import headingPackage from 'components/objects/headingBlock/package';
import bannerPackage from 'components/objects/banner/package';
import uspPackage from 'components/objects/usp/package';
import imageTilesPackage from 'components/objects/imageTiles/package';
import vdColumnTemplatePackage from 'components/visualDesigner/package';
import childMenuItemsPackage from 'components/objects/childMenuItems/package';
import productSetPackage from 'components/objects/productSet/package';
import imagePackage from 'components/objects/imageBlock/package';
import searchBarPackage from 'components/objects/searchBarBlock/package';
import spacerPackage from 'components/objects/spacerBlock/package';
import logoPackage from 'components/objects/logo/package';
import languageSelectorPackage from 'components/objects/languageSelector/package';
import ssoCrossLoginSelectorPackage from 'components/objects/ssoCrossLoginSelector/package'; //[127901] [Palfinger] 3.5. Log in to multiple shops
import basketSummaryPackage from 'components/objects/basket/summary/package';
import navigationPackage from 'components/objects/navigation/package';
import accountMenuPackage from 'components/objects/accountMenu/package';
import productSuggestionsPackage from 'components/objects/productSuggestions/package';
import callUsMessagePackage from 'components/objects/callUsMessage/package';
import copyrightTextPackage from 'components/objects/copyrightText/package';
import newsletterSubscriptionPackage from 'components/objects/newsletter/package';

const defaultComponents = {
  ...htmlPackage,
  ...headingPackage,
  ...bannerPackage,
  ...uspPackage,
  ...imageTilesPackage,
  ...vdColumnTemplatePackage,
  ...childMenuItemsPackage,
  ...productSetPackage,
  ...imagePackage,
  ...searchBarPackage,
  ...spacerPackage,
  ...logoPackage,
  ...languageSelectorPackage,
  ...ssoCrossLoginSelectorPackage, //[127901] [Palfinger] 3.5. Log in to multiple shops
  ...basketSummaryPackage,
  ...navigationPackage,
  ...accountMenuPackage,
  ...productSuggestionsPackage,
  ...callUsMessagePackage,
  ...copyrightTextPackage,
  ...newsletterSubscriptionPackage,
};

export default defaultComponents;
