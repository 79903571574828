import { memo } from 'react';
import PropTypes from 'prop-types';
import ConfigurationResultLine from './ConfigurationResultLine';
import ProductLine from './ProductLine';

{/*TICKET 127899 - 3.3. Web shop connected to a Sales Org � shopping cart and ordering*/ }
const ProductLineGroup = ({ lines, viewUomAbility, currencyInfo, productBasketType }) => {
  
  return (
    lines.map((line, index) => {

      if (line.configuration)
        return (
          <ConfigurationResultLine key={index} line={line} currencyInfo={currencyInfo} viewUomAbility={viewUomAbility} />
        );

      return (
        <ProductLine key={index} line={line} currencyInfo={currencyInfo} viewUomAbility={viewUomAbility} />
      );

    })
  );
};

ProductLine.propTypes = {
  currencyInfo: PropTypes.object,
  productBasketType: PropTypes.string,
  lines: PropTypes.arrayOf(PropTypes.shape({
    product: PropTypes.shape({
      id: PropTypes.string.isRequired,
      url: PropTypes.string,
      uoms: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
      })),
      title: PropTypes.string,
      image: PropTypes.shape({
        small: PropTypes.string,
      }),
      images: PropTypes.arrayOf(PropTypes.shape({
        small: PropTypes.string,
        variantId: PropTypes.string,
      })),
    }),
    subLines: PropTypes.arrayOf(PropTypes.shape({
      variationId: PropTypes.string,
      title: PropTypes.string,
    })),
    uom: PropTypes.shape({
      id: PropTypes.string.isRequired,
      description: PropTypes.string,
    }),
    quantity: PropTypes.number.isRequired,
    price: PropTypes.number,
  })),
};

export default memo(ProductLineGroup);