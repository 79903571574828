import { memo } from 'react';
import PropTypes from 'prop-types';
import { abilityPropType } from 'behavior/user';
import SummaryLine from './SummaryLine';
import { ProductTrackingContext } from 'components/objects/analytics';
import { StandardEventSource } from 'behavior/analytics';
import { iEquals } from 'utils/helpers';

const ProductLine = ({ line, currencyInfo, viewUomAbility }) => {
  const { product, subLines, price, quantity } = line;
  const uomId = line.uom && line.uom.id;
  const uomDescription = line.uom && line.uom.description;
  const { id, url, uoms, title, image, images } = product;

  const uom = uomId && uoms && uoms.find(u => u.id === uomId);
  let variantTitle = null;
  let productImage = image;

  if (subLines && subLines.length) {
    const { variationId, title } = subLines[0];
    variantTitle = title;
    productImage = images.find(i => iEquals(i.variantId, variationId)) || productImage;
  }

  const trackingData = {
    product: { ...product, price, uom: line.uom },
    trackingSource: StandardEventSource.ShoppingCartMiniature,
  };

  return (
    <ProductTrackingContext.Provider value={trackingData}>
      <SummaryLine
        productId={id}
        productTitle={title}
        productUrl={url}
        productImage={productImage}
        variantTitle={variantTitle}
        quantity={quantity}
        price={price}
        uomId={uom ? uom.id : uomId}
        uomDescription={uom ? uom.description : uomDescription}
        currencyInfo={currencyInfo}
        viewUomAbility={viewUomAbility}
      />
    </ProductTrackingContext.Provider>
  );
};

ProductLine.propTypes = {
  line: PropTypes.shape({
    product: PropTypes.shape({
      id: PropTypes.string.isRequired,
      url: PropTypes.string,
      uoms: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
      })),
      title: PropTypes.string,
      image: PropTypes.shape({
        small: PropTypes.string,
      }),
      images: PropTypes.arrayOf(PropTypes.shape({
        small: PropTypes.string,
        variantId: PropTypes.string,
      })),
    }),
    subLines: PropTypes.arrayOf(PropTypes.shape({
      variationId: PropTypes.string,
      title: PropTypes.string,
    })),
    uom: PropTypes.shape({
      id: PropTypes.string.isRequired,
      description: PropTypes.string,
    }),
    quantity: PropTypes.number.isRequired,
    price: PropTypes.number,
  }),
  currencyInfo: PropTypes.object,
  viewUomAbility: abilityPropType.isRequired,
};

export default memo(ProductLine);