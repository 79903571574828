import { map, first, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { createLoadOptions } from '../handler';
import { searchPageQuery, searchPreviewPageQuery, productsQuery } from './queries';
import { PageComponentNames } from 'behavior/pages/componentNames';
import { productsGeneralInfoLoaded } from '../actions';
import { initPageContent, initSystemPageContent, loadSystemPageQuery } from 'behavior/pages/system';
import { trackSearch } from 'behavior/analytics/actions';
import { areAnalycticsSettingsLoaded } from 'behavior/analytics';

export default ({ params, options }, state$, { api }) => {
  const handle = ({ settings }) => {
    const { showThumbnails } = settings.search;
    const { defaultViewMode, viewModeSwitchEnabled, pagingType } = settings.productList;
    const { sort, viewMode, previewToken } = params;
    const selectedViewMode = viewMode || defaultViewMode;

    if (previewToken) {
      return api.graphApi(searchPreviewPageQuery).pipe(
        map(({ pages: { search: page }, settings: { search: { preset } } }) => !page ? null : ({
          page: {
            ...page,
            component: PageComponentNames.Search,
            id: 'search',
            viewModeSwitchEnabled,
            showThumbnails,
            pagingType,
            selectedSorting: sort,
            defaultViewMode,
            selectedViewMode,
            lastViewedEnabled: false,
            relevanceAvailable: true,
            products: Array.from(Array(3)).map((_, index) => ({
              id: (index + 1).toString(),
              url: '',
              title: '',
              isOrderable: true,
              calculated: true,
              productConfiguratorInfo: {},
            })),
            totalCount: 3,
            preset,
          },
        })),
        initSystemPageContent(),
      );
    }

    const loadOptions = createLoadOptions(params, { ...options, viewMode: selectedViewMode }, settings.productList);

    if (!loadOptions.keywords) {
      return api.graphApi(loadSystemPageQuery('search')).pipe(
        map(({ pages: { search: page } }) => !page ? null : ({
          page: {
            ...page,
            id: 'search',
            products: [],
            totalCount: 0,
            showThumbnails,
            viewModeSwitchEnabled,
            component: PageComponentNames.Search,
          },
        })),
        initSystemPageContent(),
      );
    }

    const state = state$.value;
    const apiOptions = {
      options: loadOptions,
      loadLargeImages: false,
      loadCategories: state.analytics && state.analytics.isTrackingEnabled,
    };

    if (options?.productsOnly) {
      return api.graphApi(productsQuery, apiOptions).pipe(
        map(({ catalog: { products: foundProducts } }) => {
          if (!foundProducts)
            return null;

          const { products, totalCount } = foundProducts;
          const appendProducts = options.appendProducts;
          const size = loadOptions.page.size;

          return {
            page: state.page,
            action$: of(productsGeneralInfoLoaded(products, appendProducts, size, totalCount, sort)),
          };
        }),
      );
    }

    return api.graphApi(searchPageQuery({
      isInsiteEditor: state.insiteEditor.initialized,
    }), apiOptions).pipe(
      map(({ pages: { search: pageResponse }, catalog: { products: foundProducts, contentSearchItems: foundContentSearchItems }, settings: { search: { preset } } }) => {
        if (!pageResponse || !foundProducts)
          return null;

        const { products, totalCount, facets } = foundProducts;

        const page = {
          ...initPageContent(pageResponse),
            id: 'search',
            products,
            totalCount,
            facets,
            viewModeSwitchEnabled,
            showThumbnails,
            pagingType,
            selectedSorting: sort,
            defaultViewMode,
            selectedViewMode,
            lastViewedEnabled: false,
            relevanceAvailable: true,
            component: PageComponentNames.Search,
            preset,
        };

        if (state.analytics?.isTrackingEnabled && params.q) {
          return {
            page,
            action$: of(trackSearch(params.q)),
          };
        }

        return { page };
      }),
      initSystemPageContent(),
    );
  };

  if (state$.value.settings.loaded)
    return handle(state$.value);

  return state$.pipe(
    first(areAnalycticsSettingsLoaded),
    switchMap(handle),
  );
};
