//[127901] [Palfinger] 3.5. Log in to multiple shops

import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { VerticalSliding } from 'components/primitives/transitions';
import { memo, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useOnChange } from 'utils/hooks';
import Body from './Body';
import MobileHeader from './MobileHeader';
import styles from './SsoCrossLoginSelector.module.scss';
import useSsoCrossLoginSelectorBehavior from './useSsoCrossLoginSelectorBehavior';

const MobileSsoCrossLoginSelector = () => {
  const {
    websitesReady,
    selectedWebsite,
    websitesList,
  } = useSsoCrossLoginSelectorBehavior();
  const [opened, setOpened] = useState(false);
  const isLoading = useSelector(state => state.isLoading);

  const handleOpen = useCallback(e => {
    if (e.type === 'keydown') {
      const isTargetKey = e.key === 'Enter' || e.which === 13;

      if (!isTargetKey)
        return;
    }

    setOpened(!opened);
  }, [opened]);
  const handleClose = useCallback(() => setOpened(false), []);
  const handleExit = useCallback(node => node.classList.add(styles.collapsing), []);
  const handleExited = useCallback(node => node.classList.remove(styles.collapsing), []);

  useOnChange(() => isLoading && handleClose(), [isLoading], false);

  if (!websitesReady || websitesList.length <= 0)
    return null;

  return (
    <div className={styles.mobileContainer}>
      <div
        tabIndex="0"
        role="button"
        disabled={isLoading || null}
        className={styles.mobileOpener}
        onKeyDown={handleOpen}
        onClick={handleOpen}
        aria-controls="mobileSsoCrossLoginSelector"
      >
        <MobileHeader selectedWebsite={selectedWebsite} />
        <span className={styles.caret}>
          <FontAwesomeIcon icon={faCaretDown} rotation={opened ? 180 : null} />
        </span>
      </div>
      <VerticalSliding
        id="mobileSsoCrossLoginSelector"
        containerClass={styles.contentHolder}
        expanded={opened}
        onClick={handleClose}
        onExit={handleExit}
        onExited={handleExited}
      >
        <Body
          websitesList={websitesList}
          className={styles.mobileItem}
        />
      </VerticalSliding>
    </div>
  );
};

export default memo(MobileSsoCrossLoginSelector);
