import type { Action } from 'redux';
import type { Epic } from 'behavior/types';
import { ofType } from 'redux-observable';
import {
  filter,
  ignoreElements,
  tap,
} from 'rxjs/operators';
import { VIEWER_CHANGED } from 'behavior/events';
import { AnalyticsAction, ANALYTICS_DATALAYER_READY } from './actions';
import { getDataLayer } from './dataLayer/index';
import { User } from 'behavior/user/types';

type ViewerChangedAction = Action<typeof VIEWER_CHANGED>;

const epic: Epic<AnalyticsAction | ViewerChangedAction> = (action$, state$, _) => action$.pipe(
  ofType(VIEWER_CHANGED, ANALYTICS_DATALAYER_READY),
  filter(_ => state$.value.analytics?.isTrackingEnabled === true),
    tap(_ => {
        const trackUser = getDataLayer(state$.value).trackUser;
        if (!trackUser.isSupported)
            return;

        const input = {
            userId: state$.value.user.id,
        };

        trackUser.push(input);
    }),
  ignoreElements(),
);

export default epic;
