//[127901] [Palfinger] 3.5. Log in to multiple shops

import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export default () => {
  const { ssoUserRoles, websiteId } = useSelector(state => state.user);

    const selectedWebsite = websiteId;
    const websitesList = useMemo(() => websiteId != null && ssoUserRoles != null  ? [
      ...ssoUserRoles.filter(x => x !== websiteId),
    ] : ssoUserRoles, [ssoUserRoles, websiteId]);

  return {
    websitesReady: !!selectedWebsite && !!websitesList,
    selectedWebsite,
    websitesList,
  };
};
