//[127901] [Palfinger] 3.5. Log in to multiple shops

import { SimpleText } from 'components/sanaText';
import PropTypes from 'prop-types';
import styles from './SsoCrossLoginSelector.module.scss';

const Body = ({
  websitesList,
  className = '',
}) => {
  const linkClassName = `${styles.item} ${className}`;

  return websitesList.map(website => {
    const url = '/profile/crossloginrequest?webshop=' + website;

    return (
      <a key={website} href={url} className={linkClassName} >
        <SimpleText textKey={"CrossLogin_" + website} />
      </a>
    );
  });
};

Body.propTypes = {
  websitesList: PropTypes.arrayOf(PropTypes.string),
};

export default Body;
