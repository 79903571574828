import { ofType } from 'redux-observable';
import { merge, of, from, EMPTY, concat } from 'rxjs';
import { mergeMap, exhaustMap, first, tap } from 'rxjs/operators';
import { retryWithToast, catchApiErrorWithToast } from 'behavior/errorHandling';
import {
  checkoutInfoUpdated,
  CHECKOUT_SUBMIT_REQUESTED,
  saveAdditionalInfo,
} from './actions';
import {
  trackCheckoutOption,
} from 'behavior/analytics';
import {
  getSubmitMutation,
  getRefreshCheckoutQuery,
} from './queries';
import {
  updateShippingAddressIfNeeded,
  adjustGuestProfileData,
  adjustShippingMethodData,
  adjustPaymentMethodData,
  adjustCheckoutAddresses,
} from './helpers';
import { routesBuilder } from 'routes';
import { basketReceived } from 'behavior/basket';
import { navigateTo } from 'behavior/events';
import { reloadLocation } from 'behavior/routing';
import { getStepNumber, getMethod } from './utils';
import { Steps } from './constants';
import { CheckoutPresets } from 'behavior/settings/constants';
import { requestAppStateUpdate, APP_STATE_UPDATED } from 'behavior/app';
import { postForm } from '../actions';
import { toasts } from 'behavior/toasts';
import { renderHTML } from 'utils/render';
import { DocumentType } from 'behavior/documents';

export default function createEpic(isIdleSubject) {
  return function (action$, state$, deps) {
    const isQuote = () => state$.value.page.info?.isQuote || false;
    const isPromotion = () => !!state$.value.page.info?.quote;
    const { api, logger } = deps;

    const checkoutSubmit$ = action$.pipe(
      ofType(CHECKOUT_SUBMIT_REQUESTED),
      exhaustMap(action => {
        const { additionalInfo } = action.payload;

        const state = state$.value;
        if (state.app.offlineMode && state.page.info.paymentMethods) {
          const actions = [requestAppStateUpdate()];
          if (additionalInfo)
            actions.push(saveAdditionalInfo(additionalInfo));

          return merge(from(actions), action$.pipe(
            ofType(APP_STATE_UPDATED),
            first(),
            mergeMap(action => {
              if (action.payload.offlineMode)
                return submit();

              return of(checkoutInfoUpdated({
                incompleteSteps: [
                  { type: Steps.Payment, titleKey: (isQuote() ? 'Quote' : '') + 'CheckoutStep_PaymentMethods' },
                ],
              }));
            }),
          ));
        }

        return submit(additionalInfo);
      }),
    );

    return checkoutSubmit$;

    function submit(additionalInfo) {
      isIdleSubject.next(false);

      const params = { asQuote: isQuote() };

      if (additionalInfo)
        params.input = additionalInfo;

      return api.graphApi(getSubmitMutation(additionalInfo, isPromotion()), params, { retries: 0 }).pipe(
        mergeMap(({ checkout: { submit } }) => {

          const state = state$.value;
          const isOneStep = state.settings.checkout.pagePreset === CheckoutPresets.OneStep;
          if (submit.incompleteSteps && submit.incompleteSteps.length)
            return updateIncompleteCheckoutInfo(submit.info, submit.incompleteSteps, isOneStep);

          if (submit.nextAction)
            return executeNextAction(submit.nextAction);

          const route = getRouteToNavigate(submit);
          const actions = [navigateTo(route)];

          if (isOneStep && state.analytics.isTrackingEnabled)
            addTrackCheckoutAction(state, actions);

          if (submit.invalidBasket)
            return actions;

          addClearBasketAction(actions);

          return actions;
        }),
        catchApiErrorWithToast(undefined, of(reloadLocation()).pipe(
          tap(_ => isIdleSubject.next(true)),
        )),
        retryWithToast(action$, logger, () => {
          isIdleSubject.next(true);
          return EMPTY;
        }),
      );
    }

    function updateIncompleteCheckoutInfo(checkoutInfo = {}, incompleteSteps, isOneStep) {
      checkoutInfo.incompleteSteps = incompleteSteps;
      isIdleSubject.next(true);

      const state = state$.value,
        isGuest = state.page.info.isGuest;

      if (!isOneStep)
        return of(checkoutInfoUpdated(checkoutInfo));

      return concat(
        of(checkoutInfoUpdated(checkoutInfo)),
        api.graphApi(getRefreshCheckoutQuery(isGuest, isPromotion()), {
          asQuote: isQuote(),
          maxLines: state.settings.checkout.maxOverviewLines + 1,
        }).pipe(
          mergeMap(({ checkout: checkoutInfo, viewer }) => {
            if (!checkoutInfo)
              return EMPTY;

            adjustPaymentMethodData(checkoutInfo);
            adjustShippingMethodData(checkoutInfo);
            adjustGuestProfileData(checkoutInfo);

            if (isGuest)
              return of(checkoutInfoUpdated(checkoutInfo));

            adjustCheckoutAddresses(checkoutInfo, viewer);
            return updateShippingAddressIfNeeded(checkoutInfo, state$, deps).pipe(
              mergeMap(updateAddress => {
                if (updateAddress)
                  return of(checkoutInfoUpdated(checkoutInfo), updateAddress);

                return of(checkoutInfoUpdated(checkoutInfo));
              }),
            );
          }),
        ),
      );
    }

    function executeNextAction(nextAction) {
      if ('values' in nextAction)
        return of(postForm(nextAction));

      if ('message' in nextAction) {
        if (nextAction.message)
          toasts.info(
            nextAction.isHtmlMessage
              ? renderHTML(nextAction.message)
              : nextAction.message,
            { autoDismiss: false },
          );

        window.location.href = nextAction.url;
        return EMPTY;
      }

      logger.error('nextAction field is not handled.', nextAction);
      throw new Error('Unexpected nextAction.');
    }

    function getRouteToNavigate(submit) {
      if (submit.invalidBasket) {
        const checkoutInfo = state$.value.page.info.quote;
        return checkoutInfo ? routesBuilder.forDocument(checkoutInfo.quote.id, DocumentType.Quote) : routesBuilder.forBasket();
      }

      const transaction = submit.transaction;

      if (transaction.cancelled)
        return routesBuilder.forOrderCancelled(transaction.id);

      if (transaction.failed)
        return routesBuilder.forOrderFailed(transaction.id);

      if (transaction.isPaymentError)
        return routesBuilder.forPaymentError(transaction.id);

      return routesBuilder.forOrderSubmit(transaction.id);
    }
  };
}

function addTrackCheckoutAction(state, actions) {
  const shippingOption = state.page.info.shippingAddress.shippingOption;
  actions.push(trackCheckoutOption({
    step: getStepNumber(Steps.Address),
    option: shippingOption,
  }));

  const { shippingMethods, shippingMethodId } = state.page.info;
  const shippingMethod = getMethod(shippingMethods, shippingMethodId);

  if (shippingMethod) {
    actions.push(trackCheckoutOption({
      step: getStepNumber(Steps.Shipping),
      option: shippingMethod,
    }));
  }

  const { paymentMethods, paymentMethodId } = state.page.info;
  const paymentMethod = getMethod(paymentMethods, paymentMethodId);

  if (paymentMethod) {
    actions.push(trackCheckoutOption({
      step: getStepNumber(Steps.Payment),
      option: paymentMethod,
    }));
  }
}

function addClearBasketAction(actions) {
  actions.push(basketReceived({
    id: '',
    productLines: {},
    totalCount: 0,
    cleared: true,
    modifiedDate: Date.now(),
  }, 0));
}
