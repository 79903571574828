export const enum StandardEventSource {
    SearchInput = 'Search bar',
    CheckoutOffers = 'Checkout offers',
    SearchResult = 'Search results',
    LastViewedProducts = 'Last viewed products',
    LastViewedProductsPage = 'Last viewed products page',
    ShoppingCartPage = 'Shopping cart page',
    ShoppingCartMiniature = 'Shopping cart miniature',
    RelatedProducts = 'Related products',
    CompareProductsPage = 'Compare products page',
    CompareProductsWidget = 'Compare products widget',
    WishList = 'WishList',
    SalesAgreement = 'Sales agreement',
    QuickOrder = 'Quick order',
    ReturnOrder = 'Return order',
    ProductGroup = 'Product group',
    VariantsMatrix = 'Variants matrix',
    OrderTemplate = 'OrderTemplate',
}

export const enum AnalyticsType {
    UA = 'UA', 
    GA4 = 'GA4', 
}
