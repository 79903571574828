import type { CustomEventSource, ProductCategories, ProductInputData } from 'behavior/analytics/types';
import type { StandardEventSource } from 'behavior/analytics/constants';


export const roundDecimal = (quantity: number | undefined) => quantity && quantity < 1 ? 1 : quantity;

export const addPropIfNotNull = <T, K extends keyof T>(obj: T, prop: K, value: T[K] | null) => {
    if (value !== undefined && value !== null)
        obj[prop] = value;
};

export const getProductCategories = (categoriesPaths?: Array<{ categories: ProductCategories }>): Array<string> => {
    if (!categoriesPaths?.length)
        return [];

    return reduceCategories(categoriesPaths[0].categories);
};

const isCustomEventSource = (source: StandardEventSource | CustomEventSource): source is CustomEventSource =>
    !!source && (source as CustomEventSource).name !== undefined;

export const eventSourceToList = (eventSource: StandardEventSource | CustomEventSource) =>
    isCustomEventSource(eventSource) ? { ...eventSource } : { id: eventSource, name: eventSource };

export const getProductInfo = ({ id, title, uom, productGroup }: ProductInputData): { id: string; name: string } => {
    if (productGroup) {
        return {
            id: productGroup.id,
            name: productGroup.title ?? productGroup.id,
        };
    }

    const uomId = uom?.id;
    return {
        id: (uomId && `${id}_${uomId}`) || id,
        name: title!,
    };
};

const reduceCategories = (categories: ProductCategories): Array<string> => {
    if (typeof categories === 'string')
        return [categories];

    if (!categories.length)
        return [];

    if (typeof categories[0] === 'string')
        return categories as Array<string>;

    return (categories.slice(0, 5) as any[]).map(c => c.name);
};
