import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { retryWithToast, catchApiErrorWithToast } from 'behavior/errorHandling';
import {
  checkoutInfoUpdated,
  CHECKOUT_ADDITIONAL_INFO_SAVE,
  CHECKOUT_DELIVERY_DATE_SAVE, //[127904] [Palfinger] 3.8. Discount based on requested delivery date and shipping method
} from './actions';
import { getSaveAdditionalInfoMutation, getSaveDeliveryDateMutation } from './queries';

export default function createEpic(waitForSubmit) {
  return function (action$, state$, { api, logger }) {
    const isQuote = () => state$.value.page.info?.isQuote || false;
    const isPromotion = () => !!state$.value.page.info?.quote;

    const saveAdditionalInfo$ = action$.pipe(
      ofType(CHECKOUT_ADDITIONAL_INFO_SAVE),
      switchMap(action => waitForSubmit(() => {
        // 'Update' with empty object is used to trigger redux state update, so it will result in update of object reference in hook(s) dependencies.
        const emptyInfoUpdatedAction = checkoutInfoUpdated({});

        return api.graphApi(getSaveAdditionalInfoMutation(!!state$.value.page.info?.quote), { input: action.payload }).pipe(
          map(({ checkout }) => checkoutInfoUpdated(checkout.additionalInfo.save.info)),
          catchApiErrorWithToast(undefined, of(emptyInfoUpdatedAction)),
          retryWithToast(action$, logger),
        );
      })),
    );

    //[127904] [Palfinger] 3.8. Discount based on requested delivery date and shipping method
    const saveDeliveryDate$ = action$.pipe(
      ofType(CHECKOUT_DELIVERY_DATE_SAVE),
      switchMap(action => waitForSubmit(() => api.graphApi(getSaveDeliveryDateMutation(isPromotion()), {
        deliveryDate: action.payload,
        asQuote: isQuote(),
        maxLines: state$.value.settings.checkout.maxOverviewLines + 1,
      }).pipe(
        map(({ checkout }) => {
          if (checkout) {
            const { success, info } = checkout.additionalInfo.saveDeliveryDate;

            if (success) {
              return checkoutInfoUpdated(info);
            }
          }

          return navigateOnIncorrect(state$.value.page.info);
        }),
        retryWithToast(action$, logger),
      ))),
    );

    return merge(saveAdditionalInfo$, saveDeliveryDate$);
  };
}
