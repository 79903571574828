import type { AppState } from 'behavior';
import type {
    ProductClickInput,
    ProductInputData,
    ProductDetailsViewInput,
    ProductListViewInput,
    TrackSearchInput,
    ProductsAddToBasketInput,
    CustomEventSource,
    ProductsRemoveFromBasketInput,
    TrackUserInput,
    CheckoutStartInput,
    PaymentInfoInput,
    ShippingInfoInput,
    PurchaseInput,
    ProductLine,
    VariantProductLine,
    PageViewInput,
} from 'behavior/analytics/types';
import {
    eventSourceToList,
    getProductCategories,
    getProductInfo,
    addPropIfNotNull,
    roundDecimal,
} from './common';

import type { StandardEventSource } from 'behavior/analytics/constants';
import type { ActionPricesPresentationType } from 'behavior/settings/types';
import { getDiscountAmount } from 'utils/product/discountUtils';

import { createUrl } from 'behavior/routing/helpers';

type Item = {
    item_id: string;
    item_name: string;
    affiliation?: string;
    coupon?: string;
    currency?: string;
    discount?: number;
    index?: number;
    item_category?: string;
    item_category2?: string;
    item_category3?: string;
    item_category4?: string;
    item_category5?: string;
    item_list_id?: string;
    item_list_name?: string;
    item_variant?: string;
    location_id?: string;
    price?: number | null;
    quantity?: number;
};

// As per documentation, recommended approach is to clear ecommerce variable before pushing another ecommerce event.
// https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm
export const createEmptyECommercePayload = () => ({ ecommerce: null });

export const createProductClickPayload = ({ product, source }: ProductClickInput, state: AppState) => {
    const currency = getCurrencyCode(state);
    const actionPricesMode = getActionPricesPresentationType(state);
    const { id: item_list_id, name: item_list_name } = eventSourceToList(source);

    return {
        event: 'select_item',
        ecommerce: {
            item_list_id,
            item_list_name,
            items: [getItem(product, actionPricesMode, currency)],
        },
    };
};

export const createProductDetailsViewPayload = ({ product }: ProductDetailsViewInput, state: AppState) => {
    const currency = getCurrencyCode(state);
    const actionPricesMode = getActionPricesPresentationType(state);
    const items = [getItem(product, actionPricesMode)];
    const value = getItemsValue(items);

    return {
        event: 'view_item',
        ecommerce: {
            currency,
            items,
            value,
        },
    };
};

export const createProductListViewPayload = ({ products, source, paging }: ProductListViewInput, state: AppState) => {
    const currency = getCurrencyCode(state);
    const actionPricesMode = getActionPricesPresentationType(state);
    const { id: item_list_id, name: item_list_name } = eventSourceToList(source);

    const indexBase = (paging === undefined) ? undefined : paging.pageIndex * paging.pageSize;

    return {
        event: 'view_item_list',
        ecommerce: {
            item_list_id,
            item_list_name,
            items: products.map(product => getItem(product, actionPricesMode, currency, indexBase)),
        },
    };
};

export const createSearchPayload = ({ keywords }: TrackSearchInput) => ({
    event: 'search',
    search_term: keywords,
});

export const createProductsAddToBasketPayload = ({ products, source }: ProductsAddToBasketInput, state: AppState) => {
    const currency = getCurrencyCode(state);
    const actionPricesMode = getActionPricesPresentationType(state);
    const items = products.map(product => getItem(product, actionPricesMode, undefined, undefined, source));
    const value = getItemsValue(items);

    return {
        event: 'add_to_cart',
        ecommerce: {
            currency,
            value,
            items,
        },
    };
};

export const createProductsRemoveFromBasketPayload = ({ products }: ProductsRemoveFromBasketInput, state: AppState) => {
    const currency = getCurrencyCode(state);
    const actionPricesMode = getActionPricesPresentationType(state);
    const items = products.map(product => getItem(product, actionPricesMode));
    const value = getItemsValue(items);

    return {
        event: 'remove_from_cart',
        ecommerce: {
            currency,
            value,
            items,
        },
    };
};

export const createUserIdPayload = ({ userId }: TrackUserInput) => ({ userId });

export const createCheckoutStartPayload = ({ products, promotion }: CheckoutStartInput, state: AppState) => {
    const currency = getCurrencyCode(state);
    const actionPricesMode = getActionPricesPresentationType(state);
    const items = products.map(product => getItem(product, actionPricesMode));
    const value = getItemsValue(items);

    return {
        event: 'begin_checkout',
        ecommerce: {
            currency,
            value,
            coupon: promotion?.title,
            items,
        },
    };
};

export const createPaymentInfoPayload = ({ paymentMethod, products, promotion }: PaymentInfoInput, state: AppState) => {
    const currency = getCurrencyCode(state);
    const actionPricesMode = getActionPricesPresentationType(state);
    const items = products.map(product => getItem(product, actionPricesMode));
    const value = getItemsValue(items);

    return {
        event: 'add_payment_info',
        ecommerce: {
            payment_type: paymentMethod,
            currency,
            value,
            coupon: promotion?.title,
            items,
        },
    };
};

export const createPageViewPayload = ({ origin, pageTitle }: PageViewInput, state: AppState) => ({
    event: 'pageView',
    pageUrl: origin + createUrl(state.routing.location!),
    pageUrlFragment: state.routing.location!.hash,
    oldPageUrl: state.routing.previous ? origin + createUrl(state.routing.previous.location) : '',
    oldPageUrlFragment: state.routing.previous?.location.hash || '',
    pageTitle,
});

export const createShippingInfoPayload = ({ shippingMethod, products, promotion }: ShippingInfoInput, state: AppState) => {
    const currency = getCurrencyCode(state);
    const actionPricesMode = getActionPricesPresentationType(state);
    const items = products.map(product => getItem(product, actionPricesMode));
    const value = getItemsValue(items);

    return {
        event: 'add_shipping_info',
        ecommerce: {
            shipping_tier: shippingMethod,
            currency,
            value,
            coupon: promotion?.title,
            items,
        },
    };
};

export const createPurchasePayload = (input: PurchaseInput, state: AppState) => {
    const {
        itemLines,
        totals,
        transactionId,
        shopName,
        pricesInclTax,
    } = input;

    const currency = getCurrencyCode(state);
    const actionPricesMode = getActionPricesPresentationType(state);
    const items = extractItems(itemLines, actionPricesMode);
    const value = pricesInclTax ? totals?.totalPrice : totals?.totalExcludingTax;

    return {
        event: 'purchase',
        ecommerce: {
            currency,
            transaction_id: transactionId,
            value: value ?? 0,
            affiliation: shopName,
            shipping: totals?.shippingCost ?? 0,
            tax: totals?.taxAmount ?? 0,
            coupon: totals?.promotion?.title,
            items,
        },
    };
};

const getCurrencyCode = ({ user }: AppState) => user!.currencyId || '';
const getActionPricesPresentationType = (state: AppState) => state.settings.loaded ? state.settings.product?.actionPricesPresentationType : undefined;

export const getItem = (
    product: ProductInputData,
    actionPricesMode?: ActionPricesPresentationType,
    currency?: string,
    indexBase?: number,
    source?: StandardEventSource | CustomEventSource,
): Item => {
    const {
        variant,
        price,
        quantity,
        productGroup,
        listPrice,
        listIndex,
    } = product;

    const { id: item_id, name: item_name } = getProductInfo(product);

    const item: Item = {
        item_id,
        item_name,
    };

    const categoriesPaths = product.categoriesPaths;
    const itemCategories = getProductCategories(categoriesPaths);

    addCategories(item, itemCategories);

    if (source) {
        const { id, name } = eventSourceToList(source);
        addPropIfNotNull(item, 'item_list_id', id);
        addPropIfNotNull(item, 'item_list_name', name);
    }

    if (productGroup)
        return item;

    price && addPriceAndDiscount(item, price, currency, actionPricesMode, listPrice);

    addPropIfNotNull(item, 'item_variant', variant);
    addPropIfNotNull(item, 'quantity', roundDecimal(quantity));

    if (listIndex !== undefined)
        addPropIfNotNull(item, 'index', listIndex + (indexBase ?? 0) + 1);

    return item;
};

const addCategories = (item: Item, itemCategories: string[]) => {
    const length = itemCategories.length > 5 ? 5 : itemCategories.length;
    for (let index = 0; index < length; index++) {
        const num = index > 0 ? `${index + 1}` : '';
        const propName = `item_category${num}`;
        addPropIfNotNull(item, propName as keyof Item, itemCategories[index]);
    }
};

const addPriceAndDiscount = (
    item: Item,
    price: number,
    currency?: string,
    actionPricesMode?: ActionPricesPresentationType,
    listPrice?: number | null,
) => {
    const isViewDiscountAllowed = actionPricesMode && actionPricesMode !== 'SALES_PRICE';
    addPropIfNotNull(item, 'currency', currency);

    if (isViewDiscountAllowed && listPrice) {
        addPropIfNotNull(item, 'price', listPrice);
        addPropIfNotNull(item, 'discount', getDiscountAmount(price, listPrice));
    } else {
        addPropIfNotNull(item, 'price', price);
    }
};

const getItemsValue = (items: Item[]) => {
    let totalValue = 0;

    for (const item of items) {
        const { price, quantity, discount } = item;

        if (!price)
            continue;

        const salesPrice = discount ? price - discount : price;
        const value = !!quantity ? salesPrice * quantity : salesPrice;
        totalValue += Math.round(value * 100) / 100;
    }

    return totalValue;
};

const extractItems = (itemLines: Array<ProductLine>, actionPricesMode?: ActionPricesPresentationType) => {
    const items: ReturnType<typeof getItem>[] = [];

    itemLines.forEach(itemLine => {
        if (isVariantLine(itemLine)) {
            itemLine.sublines.forEach(subline => {
                const productInput = {
                    id: itemLine.product.id,
                    title: itemLine.title,
                    variant: subline.title,
                    uom: subline.uom,
                    price: subline.price,
                    quantity: subline.quantity,
                    categoriesPaths: itemLine.product.categoriesPaths,
                };
                const item = getItem(productInput, actionPricesMode);
                items.push(item);
            });
        } else {
            const productInput = {
                id: itemLine.product.id,
                title: itemLine.title,
                uom: itemLine.uom,
                price: itemLine.price,
                quantity: itemLine.quantity,
                categoriesPaths: itemLine.product.categoriesPaths,
            };
            const item = getItem(productInput, actionPricesMode);
            items.push(item);
        }
    });

    return items;
};

const isVariantLine = (line: ProductLine): line is VariantProductLine =>
    'sublines' in line;