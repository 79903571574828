export const SortingModes = Object.freeze({
  DateAdded: 'ADDITION',
  RecentlyModified: 'RECENT',
});

export const Updaters = Object.freeze({
  Basket: 'BASKET',
  Sync: 'SYNC',
  QuickOrder: 'QUICK_ORDER',
});

//TICKET 127899 - 3.3. Web shop connected to a Sales Org – shopping cart and ordering
export const BasketTypes = Object.freeze({
  BASKET: 'BASKET',
  SPAREPARTS: 'SPAREPARTS',
  ALL: 'ALL'
});
