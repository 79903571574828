import type { User, Viewer, Abilities, Token } from './types';
import type { StateObservable } from 'redux-observable';
import type { Action } from 'redux';
import { authenticated, loginFailed } from './actions';
import { viewerChanged, navigateTo } from 'behavior/events';
import { tap } from 'rxjs/operators';
import { routesBuilder } from 'routes';
import { Observer, Observable, from, of } from 'rxjs';
import { unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { arrayToObject } from 'utils/helpers';
import { parseQuery } from 'utils/url';
import type { Scope } from 'utils/app';
import type { AppState } from 'behavior';
import type { StoreDependencies } from 'behavior/types';

type ExpirationsObserver = Observer<Date | null>;
type GetNavigateToAction = (scope: Scope, state: AppState, loggedIn: boolean) => ReturnType<typeof navigateTo> | undefined;

export function createMapLoginResult(state$: StateObservable<AppState>, { api, scope }: StoreDependencies, expirationsObserver?: ExpirationsObserver) {
    return function (
        email: string,
        loginResult: { token: Token | null } | undefined,
        viewerResult: Viewer,
        getNavigateToAction: GetNavigateToAction = defaultGetNavigateToAction,
    ): Observable<Action> {
        if (loginResult === undefined) {
            const navigateToAction = getNavigateToAction(scope, state$.value, false);
            return of(navigateToAction || unsetLoadingIndicator());
        }

        if (loginResult.token) {
            expirationsObserver && expirationsObserver.next(loginResult.token.expiration);
            api.setAuthToken(loginResult.token.value);

            const data = createUserData(viewerResult, true);
            data.email = email;

            const actions: Array<Action> = [authenticated(data), viewerChanged()];

            const navigateToAction = getNavigateToAction(scope, state$.value, true);
            navigateToAction && actions.push(navigateToAction);

            return from(actions);
        }

        return of(unsetLoadingIndicator(), loginFailed());
    };
}

function defaultGetNavigateToAction(scope: Scope, state: AppState, loggedIn: boolean) {
    if (!loggedIn)
        return;

    const redirectTo = getBackToFromUrl(scope) || state.page.backTo;
    if (redirectTo) {
        if (redirectTo.routeData)
            return navigateTo(redirectTo.routeData, redirectTo.url);

        if (redirectTo.url)
            return navigateTo(undefined, redirectTo.url);
    }

    return navigateTo(routesBuilder.forHome());
}

export function createUserData(viewer: Viewer, isAuthenticated: boolean): User {
    const userData = { ...viewer, isAuthenticated } as User;

    if (viewer.abilities)
        userData.abilities = convertAbilities(viewer.abilities);

    return userData;
}

export function handleToken(api: StoreDependencies['api'], expirationsObserver?: ExpirationsObserver, broadcast = true) {
    return tap(<T extends { viewer?: Viewer }>(res: T) => {
        const token = res.viewer?.token;
        if (token !== undefined) {
            expirationsObserver && expirationsObserver.next(token.expiration);
            api.setAuthToken(token.value, broadcast);
            delete res.viewer!.token;
        }
    });
}

export function convertAbilities(abilities: Required<Viewer>['abilities']): Abilities {
    return arrayToObject(abilities, ability => ability.key, ability => ability.state);
}

export function getBackToFromUrl(scope: Scope): { url: string; routeData?: undefined } | undefined {
    if (scope !== 'CLIENT')
        return;

    const { search, hash } = window.location;
    if (!search)
        return;

    const query = parseQuery(search);
    const backurl = query.backurl;

    if (!backurl)
        return;

    return { url: backurl + hash };
}


