//[127900] [SSO] [Palfinger] 3.4. Single Sign On - PALDESK

import { RouteName } from 'routes';
import { of } from 'rxjs';
import { getBackTo } from '../helpers';

export default (routeData, state$, { api }) => {
  return of({
    emptyLayout: true,
    backTo: _getBackTo(routeData, state$),
  });
};

function _getBackTo(routeData, state$) {
  return getBackTo(state$, [
    RouteName.Login,
    RouteName.Registration,
    RouteName.ResetPassword,
    RouteName.ForgotPassword,
    RouteName.ChangePassword,
  ], routeData.params && routeData.params.language);
}
