import styles from './Summary.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SimpleText } from 'components/sanaText';
import ProductLineGroup from './ProductLineGroup';
import Total from './Total';
import Footer from './Footer';

const Popup = ({ basket, linesAmount, showTotals, pricesInclTax, currencyInfo, abilities, appliedSalesAgreementId }) => {
  if (!basket && !basket.productLines)
    return null;

  //TICKET 127899 - 3.3. Web shop connected to a Sales Org – shopping cart and ordering
  const productGroups = lines => {
    const groups = [];

    lines.map(line => {

      const productBasketType = line.productBasketType;
      const count = groups.filter(g => { return g.productBasketType === productBasketType; });

      if (count.length > 0) {

        const group = groups.filter(g => { return g.productBasketType === productBasketType; })[0];
        group.productLines.push(line);
      }
      else {
        groups.push({
          productBasketType,
          productLines: [line],
        });
      }

    });

    return groups;
  }

  const [createOrderAbility, createQuoteAbility, viewUomAbility] = abilities;

  const {
    totalCount,
    calculated,
    totalPrice,
    totalPriceExcludingTax,
    editDocumentType,
    isBlocked,
    productLines,
  } = basket;

  const price = pricesInclTax ? totalPrice : totalPriceExcludingTax;
  const linesToTake = Math.min(linesAmount || 3, 3);
  const groupdLines = productGroups(productLines.list);

  return (
    <>
      <h4 className={`${styles.title} ${linesAmount ? styles.mainTitle : styles.altTitle}`}>
        <SimpleText textKey={linesAmount ? 'ShoppingBasket_ProductAddedHeader' : 'SummaryPopup_Header'} />
      </h4>
      <div className={styles.productList}>
        {
          viewUomAbility &&
          groupdLines.map((groupdLine, index) => {
            const lines = groupdLine.productLines.slice(0, linesToTake);
            const { productBasketType } = groupdLine;
            //TICKET 127899 - 3.3. Web shop connected to a Sales Org – shopping cart and ordering
            return (
              <div key={(index + 2)}>
                <h4 className="summary-block-header" key={(index + 1)}><SimpleText textKey={`BasketTypes_${productBasketType}`} /></h4>
                <div className="summary-block">
                <ProductLineGroup key={index} productBasketType={productBasketType} lines={lines} currencyInfo={currencyInfo} viewUomAbility={viewUomAbility} />
                </div>  
              </div>
            );

          })
        }
      </div>
      {showTotals &&
        <Total totalCount={totalCount} calculated={calculated} currencyInfo={currencyInfo} price={price} />
      }
      {createOrderAbility && createQuoteAbility &&
        <Footer
          editDocumentType={editDocumentType}
          createOrderAbility={createOrderAbility}
          isBlocked={isBlocked}
          createQuoteAbility={createQuoteAbility}
          salesAgreementId={appliedSalesAgreementId}
        />
      }
    </>
  );
};

Popup.propTypes = {
  linesAmount: PropTypes.number,
  showTotals: PropTypes.bool,
  pricesInclTax: PropTypes.bool,
  currencyInfo: PropTypes.object,
  basket: PropTypes.shape({
    productLines: PropTypes.shape({
      list: PropTypes.array.isRequired,
    }),
    totalCount: PropTypes.number.isRequired,
    calculated: PropTypes.bool.isRequired,
    totalPrice: PropTypes.number,
    totalPriceExcludingTax: PropTypes.number,
    editDocumentType: PropTypes.any,
    isBlocked: PropTypes.bool.isRequired,
  }).isRequired,
  appliedSalesAgreementId: PropTypes.string,
};

export default connect(({
  user: { pricesInclTax },
  settings: { currency },
  basket: { salesAgreementInfo },
}) => ({
  pricesInclTax,
  currencyInfo: currency,
  appliedSalesAgreementId: salesAgreementInfo?.id,
}))(Popup);
