//[127901] [Palfinger] 3.5. Log in to multiple shops

import { SimpleText } from 'components/sanaText';
import PropTypes from 'prop-types';
import { memo } from 'react';
import styles from './SsoCrossLoginSelector.module.scss';

const Header = ({ selectedWebsite, isDesignerMode }) => {

  return (
    <div className={styles.header}>
      {isDesignerMode ? <span><SimpleText textKey="CrossLogin_Mock" /></span> : <span><SimpleText textKey={"CrossLogin_" + selectedWebsite} /></span>}
    </div>
  );
};

Header.propTypes = {
  selectedWebsite: PropTypes.string,
  isDesignerMode: PropTypes.bool,
};

export default memo(Header);
