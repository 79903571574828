//[127901] [Palfinger] 3.5. Log in to multiple shops

import { Dropdown } from 'components/primitives/dropdown';
import PropTypes from 'prop-types';
import { memo } from 'react';
import Body from './Body';
import Header from './Header';
import useSsoCrossLoginSelectorBehavior from './useSsoCrossLoginSelectorBehavior';

const SsoCrossLoginSelectorBase = ({ isDesignerMode }) => {
  const {
    websitesReady,
    selectedWebsite,
    websitesList,
  } = useSsoCrossLoginSelectorBehavior();

  if ((!isDesignerMode && !websitesReady) || (!isDesignerMode && websitesList.length <= 0))
    return null;

  return (
    <Dropdown header={<Header selectedWebsite={selectedWebsite} isDesignerMode={isDesignerMode} />} >
      {!isDesignerMode && (<Body websitesList={websitesList} />)}
    </Dropdown>
  );
};

SsoCrossLoginSelectorBase.propTypes = {
  isDesignerMode: PropTypes.bool,
};

export default memo(SsoCrossLoginSelectorBase);
