import type { AppState } from 'behavior';
import { pushToDataLayer } from './pushToDataLayer';
import type {
    CheckoutInput,
    CheckoutStartInput,
    CheckoutOptionInput,
    PageViewInput,
    ProductClickInput,
    ProductDetailsViewInput,
    ProductListViewInput,
    ProductsAddToBasketInput,
    ProductsRemoveFromBasketInput,
    PurchaseInput,
    TrackSearchInput,
    TrackUserInput,
    PaymentInfoInput,
    ShippingInfoInput,
} from '../types';
import { AnalyticsType } from 'behavior/analytics/constants';
import * as ua from './payload/universalAnalytics';
import * as ga4 from './payload/googleAnalytics4';

type DataLayerEventFactory = {
    [analyticsType in AnalyticsType]: {
        pageView: Event<PageViewInput>;
        productClick: Event<ProductClickInput>;
        productDetailsView: Event<ProductDetailsViewInput>;
        productListView: Event<ProductListViewInput>;
        productsAddToBasket: Event<ProductsAddToBasketInput>;
        productsRemoveFromBasket: Event<ProductsRemoveFromBasketInput>;
        checkout: Event<CheckoutInput>;
        checkoutStart: Event<CheckoutStartInput>;
        checkoutOption: Event<CheckoutOptionInput>;
        paymentInfoAdded: Event<PaymentInfoInput>;
        shippingInfoAdded: Event<ShippingInfoInput>;
        purchase: Event<PurchaseInput>;
        trackUser: Event<TrackUserInput>;
        trackSearch: Event<TrackSearchInput>;
    };
};

type Event<TInput> = {
    isSupported: boolean;
    push: (input: TInput) => void;
};

const mock = {
    isSupported: false,
    push: () => { },
} as const;

const notSupported = () => mock;

const createEvent = <TIn>(push: (input: TIn) => void) => ({
    isSupported: true,
    push,
});

const createUAEvent = <TIn, TOut>(
    state: AppState,
    payloadCreator: (input: TIn, state: AppState) => TOut,
) => createEvent((input: TIn) => pushToDataLayer(state, payloadCreator(input, state)));

const createGA4Event = <TIn, TOut>(
    state: AppState,
    payloadCreator: (input: TIn, state: AppState) => TOut,
) => createEvent((input: TIn) => {
    pushToDataLayer(state, ga4.createEmptyECommercePayload());
    pushToDataLayer(state, payloadCreator(input, state));
});

const dataLayerEvents = (state: AppState): DataLayerEventFactory => ({
    [AnalyticsType.UA]: {
        pageView: createUAEvent(state, ua.createPageViewPayload),
        productClick: createUAEvent(state, ua.createProductClickPayload),
        productDetailsView: createUAEvent(state, ua.createProductDetailsViewPayload),
        productListView: createUAEvent(state, ua.createProductListViewPayload),
        productsAddToBasket: createUAEvent(state, ua.createProductsAddToBasketPayload),
        productsRemoveFromBasket: createUAEvent(state, ua.createProductsRemoveFromBasketPayload),
        checkout: createUAEvent(state, ua.createCheckoutPayload),
        checkoutStart: notSupported(),
        checkoutOption: createUAEvent(state, ua.createCheckoutOptionPayload),
        paymentInfoAdded: notSupported(),
        shippingInfoAdded: notSupported(),
        purchase: createUAEvent(state, ua.createPurchasePayload),
        trackUser: createUAEvent(state, ua.createUserTrackingPayload),
        trackSearch: notSupported(),
    },
    [AnalyticsType.GA4]: {
        pageView: createGA4Event(state, ga4.createPageViewPayload),
        productClick: createGA4Event(state, ga4.createProductClickPayload),
        productDetailsView: createGA4Event(state, ga4.createProductDetailsViewPayload),
        productListView: createGA4Event(state, ga4.createProductListViewPayload),
        productsAddToBasket: createGA4Event(state, ga4.createProductsAddToBasketPayload),
        productsRemoveFromBasket: createGA4Event(state, ga4.createProductsRemoveFromBasketPayload),
        checkout: notSupported(),
        checkoutStart: createGA4Event(state, ga4.createCheckoutStartPayload),
        checkoutOption: notSupported(),
        paymentInfoAdded: createGA4Event(state, ga4.createPaymentInfoPayload),
        shippingInfoAdded: createGA4Event(state, ga4.createShippingInfoPayload),
        purchase: createGA4Event(state, ga4.createPurchasePayload),
        trackUser: createGA4Event(state, ga4.createUserIdPayload),
        trackSearch: createGA4Event(state, ga4.createSearchPayload),
    },
});

export const getDataLayer = (state: AppState) => dataLayerEvents(state)[state.analytics!.analyticsType!];