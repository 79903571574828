import type { TrackerInfo } from '../types';
import type { AppState } from 'behavior';

const push = (payload: any) => (dataLayerName: string) => {
    const dataLayer = typeof window !== 'undefined' && (<any>window)[dataLayerName];
    if (!dataLayer)
        return;

    dataLayer.push(payload);
};

export const pushToDataLayer = (
    state: AppState,
    payload: any,
) => {
    const dataLayersNames = getDataLayersNames(state.analytics!.trackers);
    if (!dataLayersNames.length)
        return;

    dataLayersNames.forEach(push(payload));
};

const getDataLayersNames = (trackers?: Array<TrackerInfo>) => {
    const dataLayersNames: string[] = [];

    if (trackers) {
        trackers.forEach(t => {
            if (!dataLayersNames.includes(t.dataLayerName))
                dataLayersNames.push(t.dataLayerName);
        });
    }
    return dataLayersNames;
};
