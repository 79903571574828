//[127905] [Palfinger] 3.9. Add order list from Paldesk to the web shop basket (punch-in)

import { PageComponentNames } from 'behavior/pages';
import { of } from 'rxjs';
import { initComponent } from '../helpers';

export default () => {
  return of({
    emptyLayout: true,
  }).pipe(
    initComponent(PageComponentNames.SsoPunchInOptions),
  );
};
