//[127901] [Palfinger] 3.5. Log in to multiple shops

import PropTypes from 'prop-types';
import SsoCrossLoginSelectorBase from './SsoCrossLoginSelectorBase';

const SsoCrossLoginSelectorBlock = ({ isDesignerMode }) => (
  <SsoCrossLoginSelectorBase isDesignerMode={isDesignerMode} />
);

SsoCrossLoginSelectorBlock.propTypes = {
  isDesignerMode: PropTypes.bool,
};

export default SsoCrossLoginSelectorBlock;
