//[127901] [Palfinger] 3.5. Log in to multiple shops

import styles from './SsoCrossLoginSelector.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';

const MobileHeader = ({ selectedWebsite }) => {

  return (
    <div className={styles.header}>
      <SimpleText textKey={"CrossLogin_" + selectedWebsite} />
    </div>
  );
};

MobileHeader.propTypes = {
  selectedWebsite: PropTypes.string,
};

export default memo(MobileHeader);
